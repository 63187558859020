<template>
  <b-sidebar
    id="add-new-tag-sidebar"
    :visible="isAddNewTaskTagSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="
      (val) => $emit('update:is-add-new-task-tag-sidebar-active', val)
    "
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">{{ button_text }} New Tag</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- Tag-->
            <validation-provider
              #default="validationContext"
              name="Tag"
              rules="required"
            >
              <b-form-group label="Tag" label-for="tag">
                <b-form-input
                  id="tag"
                  v-model="tasktagData.tag"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Tag"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Description1 -->
            <validation-provider
              #default="validationContext"
              name="Description1"
              rules="required"
            >
              <b-form-group label="Description" label-for="description">
                <b-form-textarea
                  id="description"
                  v-model="tasktagData.description"
                  placeholder="Display in task"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  style="display: none"
                />
                <quill-editor v-model="tasktagData.description" />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Background Color-->
            <validation-provider
              #default="validationContext"
              name="Background Color"
              rules="required"
            >
              <b-form-group label="Background Color" label-for="background_color">
                <b-input-group>
                <b-form-input
                  id="background_color"
                  v-model="tasktagData.background_color"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Background Color"
                />
                <b-input-group-append is-text>
                <el-color-picker v-model="tasktagData.background_color" size="mini"></el-color-picker>
                </b-input-group-append>
                </b-input-group>
                
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

             <!-- Font Color-->
            <validation-provider
              #default="validationContext"
              name="Font Color"
              rules="required"
            >
              <b-form-group label="Font Color" label-for="font_color">
                <b-input-group>
                <b-form-input
                  id="font_color"
                  v-model="tasktagData.font_color"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Font Color"
                />
                <b-input-group-append is-text>
                <el-color-picker v-model="tasktagData.font_color" size="mini"></el-color-picker>
                </b-input-group-append>
                </b-input-group>
                
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :style="{ color: tasktagData.font_color+' !important', background: tasktagData.background_color+' !important'}"
            class="demo_button"
            >
            {{ (tasktagData.tag)?tasktagData.tag:'Demo' }}


            </b-button>

            <!-- Form Actions -->

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ button_text }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </b-overlay>
      </validation-observer>
    </template></b-sidebar
  >
</template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BFormCheckbox,
  BOverlay,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import { max } from "@/libs/validations.js";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BFormCheckbox,
    vSelect,
    BOverlay,
    BInputGroup,
    BInputGroupAppend,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewTaskTagSidebarActive",
    event: "update:is-add-new-task-tag-sidebar-active",
  },
  props: {
    isAddNewTaskTagSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      max,
    };
  },
  setup(props, { emit }) {
    const loading = ref(false);

    const blankTaskTagData = {
      id: 0,
      tag: "",
      description: "",
      background_color: "#A5853D",
      font_color: "#FFFFFF",
    };
    const button_text = ref("Add");

    const tasktagData = ref(
      JSON.parse(JSON.stringify(blankTaskTagData))
    );

    const resettasktagData = () => {
      tasktagData.value = JSON.parse(
        JSON.stringify(blankTaskTagData)
      );
    };

    const toast = useToast();

    watch(
      () => [props.id, props.isAddNewTaskTagSidebarActive],
      () => {
        if (props.id > 0 && props.isAddNewTaskTagSidebarActive) {
          tasktagData.value.id = props.data.id;
          tasktagData.value.tag = props.data.tag;
          tasktagData.value.description = props.data.description;
          tasktagData.value.background_color = props.data.background_color;
          tasktagData.value.font_color = props.data.font_color;
          
          if (tasktagData.value.id > 0) {
            button_text.value = "Edit";
          } else {
            button_text.value = "Add";
          }
        } else {
          button_text.value = "Add";
        }
      }
    );

    const onSubmit = () => {
      loading.value = true;
      store
        .dispatch(
          "setting-tasktag/addTaskTag",
          tasktagData.value
        )
        .then((response) => {
          loading.value = false;
          emit("update:is-add-new-task-tag-sidebar-active", false);
          emit("refetch-data");
          toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              text: response.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          loading.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "Fail",
              text: error.response.data.message,
              icon: "HeartIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resettasktagData
    );

    return {
      tasktagData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      button_text,
      loading,
    };
  },
};
</script>

</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#add-new-tag-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.ql-editor {
  height: 300px;
}

.input-group-text{
  padding:0 1rem;
}
.demo_button{
  width:100%;
  min-height:35px;
}
</style>