import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTaskTags(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axiosIns.get('/task_tag/list', {params:queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
        })
    },
    fetchTaskTag(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`/task_tag/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    addTaskTag(ctx, data) {
        return new Promise((resolve, reject) => {
            axiosIns.post('/task_tag', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    }

  },
}
